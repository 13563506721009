@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";

$pp-primary: #2BAEB3;
$pp-secondary: #F29F05;
$pp-info: #6cd0d6;

$theme-colors: (
  "primary": $pp-primary,
  "secondary": $pp-secondary,
  "success": $success,
  "info": $pp-info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

@import "bootstrap/scss/bootstrap";

html,
body {
  font-family: "Fira Sans", sans-serif !important;
}

.full-height {
  height: 100vh;
}

.full-width {
  width: 100vw;
}

.bg-gray {
  background-color: $gray-100;
}

.btn-secondary {
  &.btn,
  &.btn:active,
  &.btn:focus,
  &.btn:visited,
  &.btn:hover {
    color: $white !important;
  }
}
