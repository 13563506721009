.nav.nav-tabs.pp-nav-tabs {
  flex-wrap: nowrap;
  max-width: inherit;
  overflow-x: auto;
}

.image-brand {
  width: 100%;
  margin: 0 auto;
  max-width: 200px;
  max-height: 150px;
}

.margin {
  max-width: 500px; 
  margin: 0 auto !important;
}