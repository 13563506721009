@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "../../index.scss";

.pp-bg-primary {
  background-color: $pp-primary;
}

.pp-bg-secondary {
  background-color: $pp-secondary;
}

.pp-bg-black {
  background-color: $dark;
}

button.pp-bg-primary,
button.pp-bg-secondary,
button.pp-bg-black {
  color: $white;
}

.pp-logo {
  width: 26px;
}

.navbar-brand.pp-logotype {
  width: 8rem;
}
img.pp-logotype {
  width: inherit;
}

span.text-truncate.navbar-truncate {
  max-width: 110px;
}
h6.text-truncate.navbar-truncate {
  max-width: 158px;
}