@import "../../index.scss";

.fake-button {
  height: 100%;

  &.color-primary {
    background-color: #2BAEB3;
    color: $white;
  }
  
  &.color-secondary {
    background-color: #F29F05;
    color: $white;
  }

  &[isdisabled] {
    background-color: grey;
    opacity: .2;
  }

  &.outline-primary {
    background-color: $white;
    border: solid 2px $pp-primary;
    color: $pp-primary; // $white;
  }
}