.option-input {
  width: 80% !important;
  margin-left: auto !important;
}

.label-option {
  min-width: 5.2rem !important;
}

.btn-back {
  background-color: #2BAEB3 !important;
  color: #fff !important;
  border-radius: 100% !important;
  position: fixed;
  right: 0.5em;
  bottom: 0.5em;
  height: 3em;
  width: 3em;
  padding: 0px !important;
}

.btn-whatsapp2 {
  background-color: #01e675 !important;
  color: #fff !important;
  border-radius: 100% !important;
  position: fixed;
  right: 1em;
  bottom: 1em;
  height: 3em;
  width: 3em;
  padding: 0px !important;
}

.sub-texto{
  font-size: 0.8rem;
}